<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="company_name" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('company_name')">
                            <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="scholarship_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('scholarship_type')">
                            <multi-selectbox 
                                v-model="formData.type" :multiple="false"
                                :options="scholarshipTypes" :validate-error="errors[0]"
                            ></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="scholarship_rate" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('scholarship_rate')">
                            <scholarship-rate-selectbox 
                                v-model="formData.rank" :rates="rateOptions" :validate-error="errors[0]"
                            ></scholarship-rate-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" :disabled="formLoading" @click="update">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import MultiSelectbox from '../../../components/interactive-fields/MultiSelectbox'
import ScholarshipRateSelectbox from '@/components/interactive-fields/ScholarshipRateSelectbox'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import CoopPublicScholarshipService from '@/services/CoopPublicScholarshipService'

export default {
    components: {
        MultiSelectbox,
        ScholarshipRateSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {
                name: null,
                type: null,
                rank: null,
            },
            scholarshipTypes: [
                {
                    value: 'coop_scholarship', 
                    text: this.$t('coop_scholarship')
                }, 
                {
                    value:'public_scholarship', 
                    text: this.$t('public_scholarship')
                }
            ],
            rateOptions: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            CoopPublicScholarshipService.get(id)
                .then(response => {
                    this.formData = response.data.data
                })
                .catch((error) => {
                    this.$toast.error(this.$t('api.' + error.data.message))
                })
        },
        async update() {
            const isValid = await this.$refs.formModalValidate.validate()

            if (isValid) {
                this.formLoading = true

                CoopPublicScholarshipService.update(this.formId, this.formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$emit('actionSuccess', true)
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('api.' + error.data.message))
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

